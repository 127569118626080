import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useToggle } from "react-use"
import { Feedback } from "./service"

type DetailProps = {
  feedback: Feedback
}

export const Detail = ({feedback}: DetailProps) => {
  // a dialog with a button to show the detail of feedback
  const [open, setOpen] = useToggle(false)
  return (
    <>
      <Button onClick={setOpen}>Detail</Button>
      <Dialog open={open} onClose={setOpen}>
        <DialogTitle>Detail</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {feedback.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setOpen}>Close</Button>
        </DialogActions>
      </Dialog>
    </>)
};