import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Chisato } from "../../../component/Chisato";
import { Delete } from "./Delete";
import { EditOrAddDialog } from "./EditDialog";
import { Filter } from "./Filter";
import { SeriesQuery, useSeriesList } from "./service";

export const SeriesManage = () => {
  const [query, setQuery] = useState<SeriesQuery>({
    page: 1,
    size: 100
  });
  const { data, isLoading, refetch } = useSeriesList(query);
  const columns = useMemo(() => [{
    headerName: 'id',
    field: 'id',
  }, {
    headerName: 'name',
    field: 'name',
  }, {
    headerName: 'mark',
    field: 'mark_id',
  }, {
    headerName: 'desc',
    field: 'description',
    flex: 1,
  }, {
    flex: 1,
    headerName: 'desc_cn',
    field: 'description_cn',
  }, {
    flex: 0,
    headerName: 'enable',
    field: 'enable',
  }, {
    headerName: 'edit',
    field: 'edit',
    width: 200,
    renderCell(params) {
      return (
        <>
          <EditOrAddDialog series={params.row} refetch={refetch}></EditOrAddDialog>
          <Delete series={params.row} refetch={refetch}></Delete>
        </>
      )
    },
  }] as GridColDef[], [refetch]);


  return (
    <div className="flex h-full flex-col">
      <div className="w-full flex">
        <Filter value={query} onChange={(v) => setQuery(v)}></Filter>
        <EditOrAddDialog refetch={refetch}></EditOrAddDialog>
      </div>
      <Chisato
        data={data?.data.data}
        loading={isLoading}
        columns={columns}
        page={query.page}
        onPageChange={(page) => { setQuery({ ...query, page }) }}
      ></Chisato>
    </div>
  );
}