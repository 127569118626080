import { Button } from "@mui/material"
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query"
import { deleteSeries, Series } from "./service";

type DeleteProps = {
  series: Series
  refetch: Function
}

export const Delete = ({ series, refetch }: DeleteProps) => {
  const { mutate } = useMutation(async () => deleteSeries(series), {
    onSuccess: () => {
      toast("Success")
      refetch()
    }
  })

  return (
    <Button size="small" color="error" onClick={() => mutate()}>Delete</Button>
  )
}