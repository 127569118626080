import { Autocomplete, TextField } from "@mui/material"
import { useSetState } from "react-use"
import { BrandQuery, useBrandList } from "../service"

type BrandFilterProps = {
  value?: number | null;
  onChange: (v: number) => void
}

export const BrandFilter = ({ value = null, onChange }: BrandFilterProps) => {

  const [query, setQuery] = useSetState<BrandQuery>({
    name: '',
    page: 1,
    size: 100
  });

  const { data } = useBrandList(query);

  return (

          <Autocomplete<number>
            sx={{width: '100%'}}
            value={value}
            onChange={(e, v) => onChange(v!)}
            disablePortal
            inputValue={query.name}
            onInputChange={(e, v) => setQuery({ name: v })}
            id="combo-box-demo"
            options={data?.data?.data?.data?.map(b => b.id!) ?? []}
            filterOptions={(x) => x}
            getOptionLabel={o => data?.data.data.data.find(b => b.id === o)?.name ?? "N"}
            renderInput={(params) => <TextField {...params} label="Brand" />}
          />

  )
}