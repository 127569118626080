import { BrandFilter } from "../BrandManage/components/BrandFilter"
import { SeriesQuery } from "./service"

type FilterProps = {
  value: SeriesQuery
  onChange: (query: SeriesQuery) => void
}

export const Filter = ({ value, onChange }: FilterProps) => {

  return (
    <div className="w-48">
      <BrandFilter value={value.mark_id} onChange={(v) => onChange({ ...value, mark_id: v })} ></BrandFilter>
    </div>
  )
}