import { createContext, useState } from "react";

export const useAuth = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));

  return {
    login(t: string) {
      localStorage.setItem("token", t);
      setToken(t);
    },
    logout() {
      localStorage.clear()
      setToken(null);
    },
    token
  }
}

export const AuthState = createContext<ReturnType<typeof useAuth>>(null!);