import { Button } from "@mui/material"
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query"
import { Brand, deleteBrand } from "./service"

type DeleteProps = {
  brand: Brand,
  refetch: Function
}

export const Delete = ({ brand, refetch }: DeleteProps) => {
  const { mutate } = useMutation(async () => deleteBrand(brand), {
    onSuccess: () => {
      toast("Success")
      refetch()
    }
  })

  return (
    <Button size="small" color="error" onClick={() => mutate()}>Delete</Button>
  )
}