import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { Router } from './Router';
import { AuthState, useAuth } from './state/authState';

function App() {
  const queryClient = useMemo(() => new QueryClient(), []);
  const authState = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthState.Provider value={authState}>
        <CssBaseline></CssBaseline>
        <ToastContainer 
        hideProgressBar={true}
        autoClose={1500}
        ></ToastContainer>
        <Router></Router>
      </AuthState.Provider>
    </QueryClientProvider>
  );
}

export default App;
