import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useSetState, useToggle } from "react-use";
import { Brand, editOrAddBrand } from "./service"

type EditDialogProps = {
  brand?: Brand,
  refetch: Function
}

export const EditOrAddDialog = ({ brand: b, refetch }: EditDialogProps) => {
  const [visible, toggleVisible] = useToggle(false);


  const [brand, setBrand] = useSetState(b);

  const { mutate } = useMutation(() => editOrAddBrand(brand), {
    onSuccess(data, variables, context) {
      toast("Success")
      toggleVisible()
      refetch()
    },
    onError(error, variables, context) {
    },
  })

  return (<>
    <Dialog open={visible} onClose={toggleVisible} >
      <DialogTitle>
        Edit Brand
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-4 mt-2 w-80">
          <TextField label="Brand name" defaultValue={b?.name} onChange={(e) => {
            setBrand({ name: e.target.value })
          }}></TextField>
          <TextField label="Brand CN name" defaultValue={b?.name_cn} onChange={
            (e) => {
              setBrand({ name_cn: e.target.value })
            }}></TextField>
          <FormControlLabel control={<Switch defaultChecked onChange={(v) => setBrand({ enable: v.target.checked ? 1 : 0 })} />} label="Enabled" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleVisible} color="secondary">Cancel</Button>
        <Button onClick={() => mutate()} color="primary" >Save</Button>
      </DialogActions>
    </Dialog>
    <Button size="small" onClick={toggleVisible}>{
      b ? "Edit" : "Add"
    }</Button>
  </>)
}