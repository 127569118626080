
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useSetState, useToggle } from "react-use";
import { BrandFilter } from "../BrandManage/components/BrandFilter";
import { editOrAddSeries, Series } from "./service";

type EditDialogProps = {
  series?: Series,
  refetch: Function
}

export const EditOrAddDialog = ({ series: b, refetch }: EditDialogProps) => {
  const [visible, toggleVisible] = useToggle(false);


  const [series, setSeries] = useSetState(b);

  const { mutate } = useMutation(() => editOrAddSeries(series), {
    onSuccess(data, variables, context) {
      toast("Success")
      toggleVisible()
      refetch()
    },
    onError(error, variables, context) {
    },
  })



  return (<>
    <Dialog open={visible} onClose={toggleVisible} >
      <DialogTitle>
        Edit Series
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-4 mt-2 w-80">
          <BrandFilter value={series.mark_id} onChange={(v) => setSeries({mark_id: v})}></BrandFilter>
          <TextField label="Series name" defaultValue={b?.name} onChange={(e) => {
            setSeries({ name: e.target.value })
          }}></TextField>
          <TextField label="Series Description" defaultValue={b?.description} onChange={
            (e) => {
              setSeries({ description: e.target.value })
            }}></TextField>
          <TextField label="Series Description CN" defaultValue={b?.description_cn} onChange={
            (e) => {
              setSeries({ description_cn: e.target.value })
            }}></TextField>
          <FormControlLabel control={<Switch defaultChecked onChange={(v) => setSeries({ enable: v.target.checked ? 1 : 0 })} />} label="Enabled" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleVisible} color="secondary">Cancel</Button>
        <Button onClick={() => mutate()} color="primary" >Save</Button>
      </DialogActions>
    </Dialog>
    <Button size="small" onClick={toggleVisible}>{
      b ? "Edit" : "Add"
    }</Button>
  </>)
}