import { request, ResponseWrapper } from "../../../utils/request"
import md5 from 'crypto-js/md5';


export type LoginData = {
  username: string
  password: string
}
export const login = (data: LoginData) => {
  return request.post<ResponseWrapper<{ token: string }>>('auth/admin_user/login', {
    user_name: data.username,
    password: md5(data.password).toString()
  });
}