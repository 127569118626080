import { useQuery } from "@tanstack/react-query";
import { Pagination, PaginationQuery, request, ResponseWrapper } from "../../../utils/request";

type User = {
  id: number,
  user_name: string
  phone: string
}

export type UserQuery = {} & PaginationQuery

export const useUserList = (query: UserQuery) => useQuery(['user-list', query],
  () => request.get<ResponseWrapper<Pagination<User>>>('/api/v1/admin/user/list', {params: {
    ...query
  }}));