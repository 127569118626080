import { AppBar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material"
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { AuthState } from "../../state/authState";

type MainFrameProps = {
  children: React.ReactNode
}

export const MainFrame = ({children}: MainFrameProps) => {
  const useAuth = useContext(AuthState);
  const drawerWidth = 240;
  const menu = useMemo(() => [{
    title: '品牌',
    link: '/brand'
  }, {
    title: '系列',
    link: '/series'
  }, {
    title: '产品',
    link: '/product'
  }, {
    title: '类型',
    link: '/type'
  }], []);
  return (
    <Box sx={{
      width: "100%",
      height: "100%",
      display: "flex",
    }}>
      <AppBar
        position="fixed"
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Permanent drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar >
          <Typography>
            Manager
          </Typography>
        </Toolbar>
        <Divider />
        <List>
          {menu.map((text, index) => (
            <ListItem key={text.title} disablePadding>
              <ListItemButton href={text.link} LinkComponent={Link}>
                <ListItemText primary={text.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[{
            title: '用户',
            link: '/user'
          }, {
            title: '反馈',
            link: '/feedback'
          }].map((text, index) => (
            <ListItem key={text.title} disablePadding>
              <ListItemButton href={text.link} LinkComponent={Link}>
                <ListItemText primary={text.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider></Divider>
        <List>
          <ListItem onClick={useAuth.logout}>
            <ListItemButton>
                <ListItemText primary="退出登录"/>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        pt: 10,
      }}>
        {children}
      </Box>
    </Box>
  )
}