import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Chisato } from "../../../component/Chisato";
import { Delete } from "./Delete";
import { EditOrAddDialog } from "./EditDialog";
import { Brand, BrandQuery, useBrandList } from "./service";

export const BrandManage = () => {
  const [query, setQuery] = useState<BrandQuery>({
    page: 1,
    size: 100
  });

  const { data, refetch } = useBrandList(query);

  const columns = useMemo(() => [{
    headerName: 'id',
    field: 'id',
  }, {
    headerName: 'name',
    field: 'name',
    flex: 1,
  }, {
    headerName: 'name_cn',
    field: 'name_cn',
    flex: 1
  }, {
    headerName: 'enabled',
    field: 'enable',
  },{
    headerName: 'edit',
    field: 'edit',
    width: 200,
    renderCell(params) {
      return (
        <>
          <EditOrAddDialog brand={params.row} refetch={refetch}></EditOrAddDialog>
          <Delete brand={params.row} refetch={refetch}></Delete>
        </>
      )
    },
  }] as GridColDef<Brand>[], [refetch]);


  return (
    <div className="flex h-full flex-col">
      <div className="w-full">
        <EditOrAddDialog refetch={refetch}></EditOrAddDialog>
      </div>
      <Chisato
        className="flex-auto"
        data={data?.data.data}
        columns={columns}
        page={query.page}
        onPageChange={(page) => {
          setQuery({ ...query, page })
        }}
      ></Chisato>
    </div>

  );
}