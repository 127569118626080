import { useQuery } from "@tanstack/react-query";
import { Pagination, PaginationQuery, request, ResponseWrapper } from "../../../../utils/request";

export type Brand = {
  id?: number,
  enable: number,
  name: string,
  name_cn: string
}

export type BrandQuery = {
  name?: string
} & PaginationQuery

export const useBrandList = (query: BrandQuery = { page: 1, size: 20 }) => useQuery(['brand-list', query],
  () => request.get<ResponseWrapper<Pagination<Brand>>>('/api/v1/admin/mark/list', {
    params: {
      ...query
    }
  }), {
    keepPreviousData: true
  });

export const editOrAddBrand = (brand: Brand) => {
  if (brand.id) {
    return request.put('/api/v1/admin/mark', brand)
  } else {
    return request.post('/api/v1/admin/mark', brand)
  }
}

export const deleteBrand = (brand: Brand) => {
  return request.delete('/api/v1/admin/mark', {
    data: {
      id: brand.id
    }
  })
}