import { Button, FormControl, Snackbar, TextField, Typography } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"
import { useContext, useState } from "react"
import { useToggle } from "react-use"
import { AuthState } from "../../../state/authState"
import { login, LoginData } from "./service"

export const Login = () => {
  const useAuth = useContext(AuthState);

  const [logindData, setLoginData] = useState<LoginData>({
    username: "",
    password: ""
  })

  const [showError, toggleShowError] = useToggle(false);
  const { mutate, error } = useMutation<Awaited<ReturnType<typeof login>>, AxiosError>(() => login(logindData), {
    onSuccess(data, variables, context) {
      useAuth.login(data.data.data.token)
    },
    onError(error, variables, context) {
      toggleShowError(true);
    }
  })

  // material ui login form
  return (
    <div className="w-full h-full flex justify-center items-center">
      <form onSubmit={(e) => {
        e.preventDefault()
        mutate()
      }} className="flex flex-col gap-4 w-96">
        <Typography variant="h5" className="text-center">
          管理系统
        </Typography>
        <TextField label="Name" onChange={
          (e) => setLoginData({ ...logindData, username: e.target.value })
        } />
        <TextField label="Password" type="password" onChange={
          (e) => setLoginData({ ...logindData, password: e.target.value })
        } />
        <Button type="submit">Login</Button>
        <Snackbar open={showError} message={error?.message} onClose={toggleShowError} autoHideDuration={1000}></Snackbar>
      </form>
    </div>

  )
}