import { DataGrid, GridColDef } from "@mui/x-data-grid"
import clsx from "clsx"
import { Pagination } from "../../utils/request"

export type ChisatoProps = {
  page: number,
  onPageChange: (page: number) => void,
  data?: Pagination<any>,
  columns: GridColDef[],
  loading?: boolean
  filter?: React.ReactNode
} & Omit<React.HTMLProps<HTMLDivElement>, 'data'>

export const Chisato = ({
  loading,
  filter,
  data = {
    data: [],
    pageination: {page: 1, size: 20, total: 0}
  }, columns, page, onPageChange,
  ...rest
}: ChisatoProps) => {
  return (
    <div className={ clsx("flex flex-col h-full w-full", rest.className)}>
      <div className="mb-2">
        {filter}
      </div>
      <DataGrid
        rows={data.data || []}
        rowCount={data.pageination.total}
        columns={columns}
        pageSize={100}
        paginationMode="server"
        onPageChange={(page) => onPageChange(page + 1)}
        page={page - 1}
        rowsPerPageOptions={[20, 50, 100]}
        initialState={{
          pagination: {
            page: 0,
            pageSize: 100
          }
        }}
      ></DataGrid>
    </div>
  )
}