import { useQuery } from "@tanstack/react-query";
import { omit } from "lodash";
import { Pagination, PaginationQuery, request, ResponseWrapper } from "../../../../utils/request";

export type Product = {
  id: number,
  sku: string,
  description: string
  description_cn: string
  color: string
  series_id: number;
  mark_id: number;
  type_ids: number[];
}

export type ProductQuery = {
  mark_id?: number,
  series_id?: number
} & PaginationQuery

export const useProductList = (query: ProductQuery) => useQuery(['product-list', query],
  () => {
    let q = query
    if (q.series_id) {
      q = omit(q, 'mark_id')
    }
    return request.get<ResponseWrapper<Pagination<Product>>>('api/v1/admin/mark_paint/list', {
      params: {
        ...q
      }
    });
  });

export const editOrAddProduct = (product: Product) => {
  if (product.id) {
    return request.put('/api/v1/admin/mark_paint', product)
  } else {
    return request.post('/api/v1/admin/mark_paint', product)
  }
}