import { useQuery } from "@tanstack/react-query"
import { PaginationQuery, request } from "../../../../utils/request"

export type PaintType = {
  id?: number,
  name: string,
  name_cn: string
}

export type PaintTypeQuery = {
} & PaginationQuery

export const usePaintTypeList = (query:  PaintTypeQuery) => useQuery(['paint-type', query], () => request.get('/api/v1/paint_type/list', {
  params: query
}))

export const editOrAddPaintType = (paintType: PaintType) => {
  if (paintType.id) {
    return request.put('/api/v1/admin/paint_type', paintType)
  } else {
    return request.post('/api/v1/admin/paint_type', paintType)
  }
}