
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, Select, Switch, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useSetState, useToggle } from "react-use";
import { BrandFilter } from "../BrandManage/components/BrandFilter";
import { editOrAddProduct, Product } from "./service";
import { usePaintTypeList } from "../PaintTypeManage/service";

type EditDialogProps = {
  product?: Product,
  refetch: Function
}

export const EditOrAddDialog = ({ product: p, refetch }: EditDialogProps) => {
  const [visible, toggleVisible] = useToggle(false);


  const [product, setProduct] = useSetState(p);

  const { mutate } = useMutation(() => editOrAddProduct(product), {
    onSuccess(data, variables, context) {
      toast("Success")
      // toggleVisible()
      refetch()
    },
    onError(error, variables, context) {
    },
  })


  const {data: types} = usePaintTypeList({
    page: 0,
    size: 1000
  });


  return (<>
    <Dialog open={visible} onClose={toggleVisible} >
      <DialogTitle>
        Edit Series
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-4 mt-2 w-80">
          <BrandFilter value={product.mark_id} onChange={(v) => setProduct({mark_id: v})}></BrandFilter>
          <TextField label="Series" defaultValue={p?.series_id} onChange={(e) => {
            setProduct({ series_id: parseInt(e.target.value) })
          }}></TextField>
          <TextField label="Color" defaultValue={p?.color} onChange={(e) => {
            setProduct({ color: e.target.value })
          }}></TextField>
          <TextField label="Product Sku" defaultValue={p?.sku} onChange={(e) => {
            setProduct({ sku: e.target.value })
          }}></TextField>
          <TextField label="Product Description" defaultValue={p?.description} onChange={
            (e) => {
              setProduct({ description: e.target.value })
            }}></TextField>
          <TextField label="Series Description CN" defaultValue={p?.description_cn} onChange={
            (e) => {
              setProduct({ description_cn: e.target.value })
            }}></TextField>

            <Select<number[]> label="Paint Type" multiple value={product.type_ids ?? []}  onChange={(v) => setProduct({
              type_ids: v.target.value as number[]
            })}>
              {types?.data?.data?.data?.map((t: any) => <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>)}
            </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleVisible} color="secondary">Cancel</Button>
        <Button onClick={() => mutate()} color="primary" >Save</Button>
      </DialogActions>
    </Dialog>
    <Button size="small" onClick={toggleVisible}>{
      p ? "Edit" : "Add"
    }</Button>
  </>)
}
