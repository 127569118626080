import { Autocomplete, TextField } from "@mui/material"
import { useEffect } from "react"
import { useSetState } from "react-use"
import { SeriesQuery, useSeriesList } from '../../SeriesManage/service'

type SeriesFilterProps = {
  markId?: number
  value?: number
  onChange: (v: number) => void
}

export const SeriesFilter = ({ markId, value, onChange }: SeriesFilterProps) => {

  const [query, setQuery] = useSetState<SeriesQuery>({
    mark_id: markId,
    page: 1,
    size: 100
  })

  useEffect(() => {
    setQuery({ mark_id: markId })
  }, [markId, setQuery])

  const { data } = useSeriesList(query)

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      value={value}
      onChange={(e, v) => onChange(v!)}
      disablePortal
      // inputValue={query.name}
      options={data?.data?.data?.data?.map(b => b.id!) ?? []}
      filterOptions={(x) => x}
      getOptionLabel={o => data?.data.data.data.find(b => b.id === o)?.name ?? "N"}
      renderInput={(params) => <TextField {...params} label="Series" />}
    ></Autocomplete>
  )
}