import axios from "axios";

export const request = axios.create({
  baseURL: 'https://api.natsuki.cc/',
})

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
})

export type ResponseWrapper<T> = {
  data: T,
  message: T
}

export type Pagination<T> = {
  data: T[],
  pageination: {
    page: number,
    size: number,
    total: number,
  }
}

export type PaginationQuery = {
  page: number,
  size: number
}