import { useQuery } from "@tanstack/react-query"
import { Pagination, PaginationQuery, request, ResponseWrapper } from "../../../utils/request"

export type Feedback = {
  content: string
  email: string
  user_id: string
  id: number
}

export type FeedbackQuery = {} & PaginationQuery

export const useFeedbackList = (query: FeedbackQuery) => useQuery(['feedback-list', query],
  () => request.get<ResponseWrapper<Pagination<Feedback>>>('/api/v1/admin/feedback/list'));