import { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MainFrame } from "./component/MainFrame";
import { Login } from "./modules/Auth/Login";
import { BrandManage } from "./modules/ColorManage/BrandManage";
import { PaintTypeManage } from "./modules/ColorManage/PaintTypeManage";
import { ProductManage } from "./modules/ColorManage/ProductManage";
import { SeriesManage } from "./modules/ColorManage/SeriesManage";
import { FeedbackManage } from "./modules/FeedbackManage";
import { UserManage } from "./modules/UserManage";
import { AuthState } from "./state/authState";

export const Router = () => {
  const useAuth = useContext(AuthState);
  return useAuth.token ? (
    <ProtectedRoutes></ProtectedRoutes>
  ) : (
    <PublicRoutes></PublicRoutes>
  )
}

const ProtectedRoutes = () => (
  <BrowserRouter>
    <MainFrame>
      <Routes>
        <Route path="/brand" element={<BrandManage />}></Route>
        <Route path="/series" element={<SeriesManage />}></Route>
        <Route path="/product" element={<ProductManage />}></Route>
        <Route path="/type" element={<PaintTypeManage />}></Route>
        <Route path="/user" element={<UserManage />}></Route>
        <Route path="/feedback" element={<FeedbackManage />}></Route>
        <Route path="/" element={<Navigate to="/brand" />}></Route>
        <Route path="*" element={<Navigate to="/brand" />}></Route>
      </Routes>
    </MainFrame>
  </BrowserRouter>
)

const PublicRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/indomitus" element={<Login />}></Route>
      <Route path="/" element={<Navigate to="/login" />}></Route>
    </Routes>
  </BrowserRouter>
)