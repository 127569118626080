import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Button } from "react-daisyui";
import { Chisato } from "../../component/Chisato";
import { Detail } from "./Detai.";
import { FeedbackQuery, useFeedbackList } from "./service";

export const FeedbackManage = () => {
  const columns = useMemo(() => [{
    headerName: 'id',
    field: 'id',
  }, {
    headerName: 'user',
    field: 'user_id',
  },{
    headerName: 'email',
    field: 'email',
    width: '280'
  }, {
    headerName: 'content',
    flex: 1,
    field: 'content',
  }, {
    headerName: 'operation',
    field: 'operation',
    renderCell: (params) => {
      return (
        <div className="flex">
          <Detail feedback={params.row} ></Detail>
        </div>
      )
    }
  }] as GridColDef[], []);

  const [query, setQuery] = useState<FeedbackQuery>({
    page: 1,
    size: 100
  });
  const { data } = useFeedbackList(query);

  return (
    <Chisato
      data={data?.data.data}
      columns={columns}
      page={query.page}
      
      onPageChange={(page) => {
        setQuery({...query, page})} }
    ></Chisato>
  );
}