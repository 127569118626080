import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { useSetState } from "react-use";
import { Chisato } from "../../../component/Chisato";
import { BrandFilter } from "../BrandManage/components/BrandFilter";
import { SeriesFilter } from "../SeriesManage/components/SeriesFilter";
import { EditOrAddDialog } from "./EditDialog";
import { Filter } from "./Filter";
import { Product, ProductQuery, useProductList, } from "./service";

export const ProductManage = () => {

  const [query, setQuery] = useSetState<ProductQuery>({
    page: 1,
    size: 100,
  });
  const { data, isLoading, refetch } = useProductList(query);

  const columns = useMemo(() => [{
    headerName: 'id',
    field: 'id',
  }, {
    headerName: 'Mark',
    field: 'mark_id',
  }, {
    headerName: 'Series',
    field: 'series_id',
  }, {
    headerName: 'name',
    field: 'sku',
  }, {
    headerName: 'desc',
    field: 'description',
    flex: 1
  }, {
    headerName: 'desc cn',
    field: 'description_cn',
    flex: 1
  }, {
    headerName: 'type',
    field: 'type_ids'
  }, {
    headerName: 'color',
    field: 'color',
    renderCell: (rowData) => {
      return (<div key={rowData.row.id} style={{
        backgroundColor: rowData.row.color,
      }} className="w-full h-full"></div>)
    }
  }, {
    headerName: 'edit',
    field: 'edit',
    width: 200,
    renderCell: (params) => {
      return (
        <span>
          <EditOrAddDialog product={params.row} refetch={refetch}></EditOrAddDialog>
          <Button size="small" color="error">Delete</Button>
        </span>
      )
    }
  }] as GridColDef<Product>[], [refetch]);

  return (
    <div className="flex h-full flex-col">
      <div className="w-full flex">
        <BrandFilter onChange={(v) =>setQuery({mark_id: v})}></BrandFilter>
        <SeriesFilter markId={query.mark_id} onChange={(v) => setQuery({series_id: v})}></SeriesFilter>
        {/* <Filter></Filter> */}
        <EditOrAddDialog refetch={refetch}></EditOrAddDialog>
      </div>
      <Chisato
        className="flex-auto"
        data={data?.data.data}
        columns={columns}
        page={query.page}
        onPageChange={(page) => {
          setQuery({ ...query, page })
        }}
      ></Chisato>
    </div>
  );
}