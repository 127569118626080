import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useSetState, useToggle } from "react-use";
import { editOrAddPaintType, PaintType } from "./service"

type EditDialogProps = {
  paintType?: PaintType,
  refetch: Function
}

export const EditOrAddDialog = ({paintType: p, refetch}: EditDialogProps) => {
  const [visible, toggleVisible] = useToggle(false);


  const [paintType, setPaintType] = useSetState(p);

  const { mutate } = useMutation(() => editOrAddPaintType(paintType), {
    onSuccess(data, variables, context) {
      toast("Success")
      toggleVisible()
      refetch()
    },
    onError(error, variables, context) {
    },
  })

  return (<>
    <Dialog open={visible} onClose={toggleVisible} >
      <DialogTitle>
        Edit Paint Type
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-4 mt-2 w-80">
          <TextField label="Type name" defaultValue={p?.name} onChange={(e) => {
            setPaintType({ name: e.target.value })
          }}></TextField>
          <TextField label="Type CN name" defaultValue={p?.name_cn} onChange={
            (e) => {
              setPaintType({ name_cn: e.target.value })
            }}></TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleVisible} color="secondary">Cancel</Button>
        <Button onClick={() => mutate()} color="primary" >Save</Button>
      </DialogActions>
    </Dialog>
    <Button size="small" onClick={toggleVisible}>{
      p ? "Edit" : "Add"
    }</Button>
  </>)
}