import { GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Chisato } from "../../component/Chisato";
import { UserQuery, useUserList } from "./service";

export const UserManage = () => {
  const columns = useMemo(() => [{
    headerName: 'id',
    field: 'id',
  }, {
    headerName: 'name',
    field: 'user_name',
  }, {
    headerName: 'phone',
    width: 180,
    field: 'phone',
  }] as GridColDef[], []);

  const [query, setQuery] = useState<UserQuery>({
    page: 1,
    size: 100
  });
  const { data } = useUserList(query);

  return (
    <Chisato
      data={data?.data.data}
      columns={columns}
      page={query.page}
      onPageChange={(page) => {
        setQuery({...query, page})} }
    ></Chisato>
  );
}