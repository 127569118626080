import { useQuery } from "@tanstack/react-query";
import { Pagination, PaginationQuery, request, ResponseWrapper } from "../../../../utils/request";

export type Series = {
  id: number,
  enable: number,
  name: string,
  mark_id: number,
  description: string,
  description_cn: string
}

export type SeriesQuery = {
  mark_id?: number
} & PaginationQuery

export const useSeriesList = (query: SeriesQuery) => useQuery(['series-list', query],
  () => request.get<ResponseWrapper<Pagination<Series>>>('api/v1/admin/mark_series/list', {
    params: {
      ...query,
    }
  }));

export const editOrAddSeries = (series: Series) => {
  if (series.id) {
    return request.put('/api/v1/admin/mark_series', series)
  } else {
    return request.post('/api/v1/admin/mark_series', series)
  }
}

export const deleteSeries = (series: Series) => {
  return request.delete('/api/v1/admin/mark_series', {
    data: {
      id: series.id
    }
  })
}